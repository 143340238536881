<template>
    <div class="page-notifications">
        <el-tabs tab-position="top">
            <el-tab-pane label="系统升级通知">
                <el-card class="box-card">
                <el-form
                    :model="maintainForm.paramsModel"
                    :rules="maintainForm.paramsRules"
                    class="demo-paramsModel"
                    label-width="160px"
                    ref="maintainForm"
                >
                    <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker
                        v-model="maintainForm.paramsModel.startTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期"
                    ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker
                        v-model="maintainForm.paramsModel.endTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期"
                    ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="通知消息" prop="remark">
                    <el-input placeholder v-model="maintainForm.paramsModel.remark" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item>
                    <el-button
                        @click="maintainMessageSubmit"
                        type="primary"
                    >发送通知</el-button>
                    </el-form-item>
                </el-form>
                </el-card>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script >
import * as funSystem from '@/api/system/sysConfig'
import moment from "moment"
export default {
    name: "pageAppNotification",
    data() {
        return {
            maintainForm: {
                paramsRules: {
                    startTime: [{ required: true, message: "请选择通知开始时间", trigger: "blur" }],
                    endTime: [{ required: true, message: "请选择通知结束时间", trigger: "blur" }],
                    remark: [{ required: true, message: "请输入通知消息内容", trigger: "blur" }]
                },
                paramsModel: {
                    startTime: "",
                    endTime: "",
                    remark: ""
                }
            }
        }
    },
    methods: {
        maintainMessageSubmit() {
            this.$refs.maintainForm.validate(async (valid) => {
            if (valid) {
                if (!moment(this.maintainForm.paramsModel.endTime).isAfter(this.maintainForm.paramsModel.startTime)) {
                    this.$message.warning("开始时间不能大于结束时间");
                    return false;
                }
                window.$common.fullLoading()
                funSystem.PublishMaintainNotification(this.maintainForm.paramsModel).then(() => {
                    this.alert("发送通知成功!").then(() => {
                    window.$common.closeFullLoading()
                    })
                })
                window.$common.closeFullLoading()
            }
            });
        }
    }
}
</script>